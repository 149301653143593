import { User } from "@api/types";
import axios from "axios";
import { AES, enc, mode, pad } from "crypto-js";
import { useCallback, useEffect, useState } from "react";

import classNames from "classnames";
import VideoBg from "../../static/images/jenni-video-bg.jpg";
import VideoPlayIcon from "../../static/images/play-icon.png";
import UserProfileBg from "../../static/images/user-profile-bg.jpg";

import bs from "../../css/BootstrapCss";
import "./CustomerProfile.css";

import { UserProgram, UserProgramFileType } from "api/src/domain/io-ts-types";
import { find } from "lodash";
import { toast } from "react-toastify";
import { BouncingDotsIndicator } from "../BouncingDotsIndicator";

const convertBinaryStringToUint8Array = (binaryString: string): Uint8Array => {
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return byteArray;
};

const createBlobUrl = (base64FileContent: string, mimeType: string) => {
  const base64Data = base64FileContent.replace(/^data:[^;]+;base64,/, "");
  const binaryString = atob(base64Data);
  const byteArray = convertBinaryStringToUint8Array(binaryString);
  const videoBlob = new Blob([byteArray], { type: mimeType });
  return URL.createObjectURL(videoBlob);
};

const triggerUIDownload = (url: string, name: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}`);
  document.body.appendChild(link);
  link.click();

  link.parentNode?.removeChild(link);
};

const getCheckupProgram = (user: User): UserProgram | undefined => {
  const programExists = find(
    user.programs,
    (p) => p.extId === "perimenopause-one-time-checkup"
  );
  return programExists && programExists;
};

const hasProgramReport = (user: User): boolean => {
  const existingProgram = getCheckupProgram(user);
  return (
    existingProgram?.analysisReportFile !== null &&
    existingProgram?.analysisVideoFile !== null
  );
};

export const CustomerProfile = ({
  user,
  program,
}: {
  user: User;
  program: UserProgram;
}) => {
  const [isReportAvailable] = useState<boolean>(hasProgramReport(user));
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const getS3FileDetails = useCallback(
    async (fileType: UserProgramFileType) => {
      const response = await fetch(
        `/api/customer-data/download/presigned-url`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            programExtId: program.extId,
            fileType: fileType,
          }),
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to retrieve presigned url.");
      }
      return await response.json();
    },
    [program]
  );

  const decryptFileContent = useCallback(
    async (aesKey: any, iv: string, encryptedFile: string) => {
      const base64AesKey = enc.Base64.parse(aesKey);
      const base64Iv = enc.Base64.parse(iv);

      return AES.decrypt(encryptedFile, base64AesKey, {
        iv: base64Iv,
        mode: mode.CBC,
        padding: pad.Pkcs7,
      });
    },
    []
  );

  const getFileAsBlobUrl = useCallback(
    async (fileType: UserProgramFileType) => {
      const s3FileDetails = await getS3FileDetails(fileType);
      const s3FileResponse = await axios.get(s3FileDetails.url, {
        responseType: "text",
      });

      const decryptedBytes = await decryptFileContent(
        s3FileDetails.aesKey,
        s3FileDetails.iv,
        s3FileResponse.data
      );
      return createBlobUrl(
        decryptedBytes.toString(enc.Utf8),
        s3FileDetails.fileContentType
      );
    },
    [getS3FileDetails, decryptFileContent]
  );

  const downloadReportPdf = useCallback(async () => {
    setDownloadingReport(true);
    try {
      const url = await getFileAsBlobUrl("analysis_report");
      triggerUIDownload(url, "terveysraportti.pdf");
    } catch (error) {
      console.error("Error getting download URL or downloading file:", error);
      toast.error(
        "Raporttidokumentin haku epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
    } finally {
      setDownloadingReport(false);
    }
  }, [getFileAsBlobUrl]);

  const downloadVideo = useCallback(async () => {
    try {
      const url = await getFileAsBlobUrl("analysis_video");
      setVideoUrl(url);
    } catch (error) {
      console.error("Error getting download URL or downloading file:", error);
      toast.error(
        "Raporttivideon haku epäonnistui. Laitathan meille viestiä osoitteeseen asiakaspalvelu@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
    }
  }, [getFileAsBlobUrl]);

  useEffect(() => {
    if (isReportAvailable) {
      downloadVideo();
    }
  }, [isReportAvailable, getFileAsBlobUrl, downloadReportPdf, downloadVideo]);

  return (
    <div className={classNames("customer-profile")}>
      <div
        className={classNames(bs["w-100"])}
        style={{
          maxWidth: "800px",
        }}
      >
        <div
          style={{
            background: `url(${UserProfileBg}) no-repeat center center`,
            backgroundSize: "cover",
            height: "350px",
            borderRadius: "8px",
          }}
          className={classNames(
            bs["d-flex"],
            bs["flex-column"],
            bs["justify-content-center"],
            bs["align-items-center"]
          )}
        >
          <span className={classNames(bs["text-uppercase"], bs["text-white"])}>
            {user.firstName} {user.lastName}
          </span>
          <h2
            className={classNames(
              bs["mt-1"],
              bs["font-weight-light"],
              bs["text-white"]
            )}
          >
            Naisen terveysprofiili
          </h2>
        </div>
        {isReportAvailable ? (
          <div className={classNames(bs["mt-5"])}>
            <div className={classNames(bs["mb-5"])}>
              <h2 className={classNames(bs["mb-4"])}>Hei {user.firstName}!</h2>
              <p>
                Alta löydät nyt lääkärisi raportin ja videon
                terveystarkastuksesi tuloksista, löydöksistä ja
                jatkosuunnitelmasta.
              </p>
              <p>Voit ladata PDF-muotoisen raportin koneellesi.</p>
              <p>
                Mikäli sinulla on kysyttävää tai haluat varata lääkärillesi
                erikseen maksullisen etätapaamisen, voit olla yhteydessä
                osoitteeseen{" "}
                <a
                  className={classNames(bs["text-dark"])}
                  href="mailto:asiakaspalvelu@viven.fi"
                >
                  asiakaspalvelu@viven.fi
                </a>
                .
              </p>
            </div>
            <div className={classNames(bs["mb-5"])}>
              <h4 className={classNames(bs["mb-4"])}>Raporttisi</h4>
              <button
                className={classNames(
                  bs["btn"],
                  bs["btn-dark"],
                  bs["d-flex"],
                  bs["justify-content-center"],
                  bs["align-items-center"]
                )}
                style={{
                  width: "300px",
                  height: "40px",
                }}
                onClick={() => !downloadingReport && downloadReportPdf()}
              >
                {downloadingReport ? (
                  <BouncingDotsIndicator color="white" />
                ) : (
                  "Lataa PDF-raporttisi tästä"
                )}
              </button>
            </div>
            <div className={classNames(bs["mb-5"])}>
              <h4 className={classNames(bs["mb-4"])}>Lääkärisi video</h4>
              <div
                className={classNames(
                  "video-container",
                  playVideo && videoUrl && "video-playing"
                )}
                role={"button"}
              >
                {playVideo && videoUrl ? (
                  <video
                    controls
                    width="100%"
                    height={"auto"}
                    src={videoUrl}
                    style={{
                      borderRadius: "8px",
                    }}
                    autoPlay
                    className="video-player"
                  >
                    Selaimesi ei tue videon toistoa.
                  </video>
                ) : (
                  <div
                    style={{
                      background: `url(${VideoBg}) no-repeat center center`,
                      backgroundSize: "cover",
                    }}
                    className={classNames(
                      "video-placeholder",
                      bs["d-flex"],
                      bs["justify-content-center"],
                      bs["align-items-center"]
                    )}
                    onClick={() => setPlayVideo(true)}
                  >
                    {playVideo && !videoUrl ? (
                      <div
                        className={classNames(
                          bs["d-flex"],
                          bs["flex-column"],
                          bs["align-items-center"]
                        )}
                      >
                        <BouncingDotsIndicator />
                        <div className={classNames(bs["mt-3"])}>
                          Ladataan videota ja puretaan salausta...
                        </div>
                      </div>
                    ) : (
                      <img
                        src={VideoPlayIcon}
                        width={100}
                        height={100}
                        alt="Raporttivideo"
                        className={classNames("play-button")}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={classNames(bs["mt-5"])}>
            <div className={classNames(bs["mb-5"])}>
              <h2 className={classNames(bs["mb-4"])}>Hei {user.firstName}!</h2>

              <p>
                Täältä löydät lääkärisi raportin ja videon terveystarkastuksesi
                tuloksista, löydöksistä ja jatkosuunnitelmasta.
              </p>
              <p>Raportin tekeminen on tällä hetkellä vielä kesken.</p>
              <p>
                Mikäli sinulla on kysyttävää tai haluat varata lääkärillesi
                erikseen maksullisen etätapaamisen, voit olla yhteydessä
                osoitteeseen{" "}
                <a
                  className={classNames(bs["text-dark"])}
                  href="mailto:asiakaspalvelu@viven.fi"
                >
                  asiakaspalvelu@viven.fi
                </a>
                .
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
